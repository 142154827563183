import { addApolloState, initializeApollo } from '@/lib/apolloClient';
import { GetStaticProps } from 'next';
import React, { Suspense } from 'react';
import Image from 'next/image';
import homepage1 from '../../public/images/homepage1.png';
import homepage2 from '../../public/images/homepage2.png';
import homepage5 from '../../public/images/homepage5.jpeg';
import homepage6 from '../../public/images/homepage6.png';
import homepage7 from '../../public/images/homepage7.png';
import homepage8 from '../../public/images/homepage8.png';
import introtext from '../../public/images/intro_text.png';
import brand1 from '../../public/images/brand1.png';
import brand2 from '../../public/images/brand2.png';
import brand3 from '../../public/images/brand3.png';
import brand4 from '../../public/images/brand4.png';
import brand5 from '../../public/images/brand5.png';

import { SectionNews } from '@/components/Shared';
import Link from 'next/link';
import { ArrowRight, Double, NextArrow } from '@/assets/icons/svg-icons';
import {
  GetAllBlogsDocument,
  GetProjectsDocument,
  useGetProjectsQuery,
} from '@/generated/graphql';
import Head from 'next/head';
import { convertTitleToSlug } from '@/utils/common';
export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo();

  await apolloClient.query({
    query: GetAllBlogsDocument,
  });

  await apolloClient.query({
    query: GetAllBlogsDocument,
    variables: {
      where: {
        category: {
          _eq: 'news',
        },
      },
    },
  });

  await apolloClient.query({
    query: GetAllBlogsDocument,
    variables: {
      where: {
        category: {
          _eq: 'report',
        },
      },
    },
  });

  await apolloClient.query({
    query: GetProjectsDocument,
    variables: {
      limit: 2,
    },
  });

  return addApolloState(apolloClient, {
    props: {},
    revalidate: 1,
  });
};

export default function Home() {
  const { data: dataProjects } = useGetProjectsQuery({
    variables: { limit: 2 },
  });

  return (
    <div className="bg-[#F8F9F3]">
      <Head>
        <title>Hạnh Phúc Xanh</title>
      </Head>
      <div className="relative">
        <Image
          quality={65}
          src={homepage1}
          alt={'logo'}
          className="w-full h-screen object-cover"
          priority
        />
        <div className="absolute z-10 bottom-0 w-full justify-center flex">
          <Image quality={65} src={introtext} alt={'text'} priority />
        </div>
      </div>

      <div className="py-[100px] flex container justify-center gap-[108px]">
        <div className="flex flex-col gap-8 w-">
          <p className="font-serifcustom text-primary text-[46px] w-[504px]">
            Ai trồng cây,
            <br /> người đó có hạnh phúc
          </p>
          <Link
            href={'/about'}
            className="flex justify-center items-center text-[#FCFCE4] w-fit duration-300 bg-green hover:text-green hover:bg-[#FCFCE4] px-8 h-[48px] uppercase rounded-[40px]"
          >
            tìm hiểu thêm
          </Link>
        </div>
        <div className="w-[347px]">
          <p className="text-primary font-light text-[18px]">
            <span className="font-medium">
              Doanh nghiệp xã hội Hạnh Phúc Xanh
            </span>{' '}
            <br /> ra đời nhằm trồng và phục hồi rừng tại Việt Nam, gia tăng sự
            kết nối giữa con người và thiên nhiên, giữa con người và bản thân,
            giữa con người và con người, từ đó thúc đẩy thái độ sống khiêm
            nhường, hài hòa với thiên nhiên.
          </p>
        </div>
      </div>

      <div className="relative h-[608px] flex flex-col items-center">
        <Image
          quality={65}
          src={homepage2}
          alt={'text'}
          priority
          className="absolute bottom-0 w-full h-full z-0 object-cover"
        />
        <div className="w-[610px] h-[1px] bg-primary absolute top-0" />
        <div className="container flex flex-col gap-10 items-center relative z-10 pt-[60px]">
          <div className="flex flex-col items-center">
            <p className="text-green font-serifcustom font-semibold text-[40px]">
              Hạnh Phúc Xanh
            </p>
            <p className="text-primary font-serifcustom text-[40px]">
              là sống nương tựa vào thiên nhiên
            </p>
          </div>
          <p className="w-[615px] text-[20px] text-center font-light leading-6">
            Chúng tôi tin rằng mỗi cây xanh trồng hôm nay sẽ giúp chúng ta dựng
            xây một tương lai kiên cường hơn, ở đó mỗi người nương tựa thiên
            nhiên và được thiên nhiên che chở.
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center container gap-[80px] pt-[80px] pb-[40px]">
        <div className="flex flex-col gap-[20px] text-primary">
          <p className="text-[20px] font-medium uppercase text-center">
            Dự án nổi bật
          </p>
          <p className="font-serifcustom text-[46px] text-center">
            Những dự án thay đổi xanh <br /> chúng tôi theo đuổi
          </p>
        </div>
        <div className="w-[1033px] flex justify-between">
          {dataProjects?.projects.map((project, index) => (
            <Link
              key={index}
              href={`/projects/${convertTitleToSlug(
                project?.title || '',
              )}-${project?.id}`}
              className="group overflow-hidden rounded-[10px] bg-darkgreen w-[306px] relative"
            >
              <p className="absolute z-10 bottom-6 w-full text-center uppercase text-[20px] text-textWhite">
                {project?.title}
              </p>
              <Image
                quality={65}
                src={`https://file.hpx.net.vn/${project?.thumbnail?.[0]?.filename}`}
                alt={'text'}
                fill
                className="group-hover:scale-105 duration-500 absolute inset-0 w-full h-full object-cover"
              />
            </Link>
          ))}

          <Link
            href={'/projects'}
            className="rounded-[10px] bg-darkgreen hover:bg-green duration-300 w-[306px] group h-[372px] gap-8 flex flex-col justify-center items-center px-6"
          >
            <p className="text-[#FCFCE4] group-hover:text-darkgreen duration-300 font-serifcustom text-[30px] text-center">
              Khám phá thêm dự án
            </p>
            <p className="text-[#B3BE72] group-hover:text-[#FCFCE4] duration-300 flex gap-3 items-center">
              Tìm hiểu thêm
              <ArrowRight className="group-hover:stroke-[#FCFCE4] duration-300" />
            </p>
          </Link>
        </div>
      </div>

      <div className="container flex items-center justify-between w-[1033px] pt-10 pb-20">
        <div className="flex flex-col gap-5">
          <Double />
          <p className="text-primary text-[36px] font-serifcustom">
            Thương rừng, thiên nhiên <br /> sẽ thương lại mình thôi
          </p>
          <div>
            <p className="text-[18px] text-primary">
              Tống Khánh Linh (Helly Tống)
            </p>
            <p className="text-[14px] text-primary font-light">
              Founder and CEO tại The Yên Concept <br />
              Co-founder tại Lại Đây Refill Station
            </p>
          </div>
          <Link
            href={'/donation'}
            className="text-end flex items-center group justify-end gap-3 pt-5 uppercase text-brown hover:text-green duration-300"
          >
            Đóng góp ngay bằng nhiều hình thức
            <NextArrow className="fill-brown group-hover:fill-green duration-300" />
          </Link>
        </div>
        <div>
          <Image
            quality={65}
            src={homepage5}
            alt={'text'}
            className="w-[449px] h-[449px] rounded-[10px] object-cover"
          />
        </div>
      </div>

      <div className="relative overflow-hidden flex justify-center items-center h-[509px]">
        <Image
          quality={65}
          src={homepage6}
          alt={'text'}
          className="absolute inset-0 z-0 w-full"
        />
        <div className="container relative z-10 flex flex-col gap-8 items-center py-10 w-[1033px] rounded-[20px] border-[3px] border-[#BA9470]">
          <p className="text-[48px] font-serifcustom text-textWhite text-center">
            Cùng chúng tôi hành động.
            <br />
            Vì tương lai phát triển bền vững
          </p>
          <p className="text-textWhite w-[670px] text-center text-[20px] font-light">
            “Thật tuyệt khi thấy những sáng kiến nhỏ nhoi đều có thể biến thành
            những điều tốt đẹp hơn”, ông Niklas Wagner - Tổng Quản Lý tại khách
            sạn New World Sài Gòn chia sẻ trong chuyến đi trồng rừng.
          </p>
          <Link
            href={'/collaboration'}
            className="text-primary hover:text-textWhite hover:bg-primary duration-300 w-fit bg-textWhite px-8 flex items-center justify-center h-[48px] uppercase rounded-[40px]"
          >
            Tổ chức của tôi sẽ tham gia
          </Link>
        </div>
      </div>

      <div className="bg-[#F8F9F3]">
        <div className="flex gap-3 container justify-between py-20 ">
          <Image
            quality={65}
            src={brand1}
            alt={'brand'}
            className="object-contain"
          />
          <Image
            quality={65}
            src={brand2}
            alt={'brand'}
            className="object-contain"
          />
          <Image
            quality={65}
            src={brand3}
            alt={'brand'}
            className="object-contain"
          />
          <Image
            quality={65}
            src={brand4}
            alt={'brand'}
            className="object-contain"
          />
          <Image
            quality={65}
            src={brand5}
            alt={'brand'}
            className="object-contain"
          />
        </div>
      </div>

      <div className="bg-[#FBFCE1]">
        <SectionNews />
      </div>
    </div>
  );
}
