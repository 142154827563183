export function chunkWords(text: string) {
  const words = text.split(' ');
  const chunks = [];

  for (let i = 0; i < words.length; i += 2) {
    // Gộp từng cặp từ lại với nhau
    chunks.push(words.slice(i, i + 2).join(' '));
  }

  return chunks;
}

export const convertTitleToSlug = (title: string) => {
  return title
    ?.toLowerCase()
    .replace(/([-])\s+/g, '') // Loại bỏ khoảng trắng sau dấu "-"
    .replace(/\s+([-])/g, '') // Loại bỏ khoảng trắng trc dấu "-"
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
    .replace(/[?,:%'"“”\b`’‘&+()]/g, '')
    .replace(/[/.]/g, '-')
    .trim()
    .split(' ')
    .join('-');
};

export const convertUrlToId = (query: string) => {
  const lastDashIndex = query?.lastIndexOf('-');
  return query?.substring(lastDashIndex + 1);
};
